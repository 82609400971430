import imageUrlBuilder from '@sanity/image-url'
import { getImageDimensions } from '@sanity/asset-utils'

export function useSanityImage() {
  const builder = imageUrlBuilder(useSanity().config as any)
  const urlFor = (source: any) => {
    return builder.image(source).auto('format')
  }

  const dims = (src: any) => {
    return getImageDimensions(src)
  }

  return {
    urlFor,
    dims,
  }
}
